<template>
	<div v-if="state === 'pc'">
		<!-- 轮播图 -->
		<div class="cont">
			<!-- <el-carousel indicator-position="none" height="700px">
				<el-carousel-item v-for="(item, index) in img" :key="index">
					<img :src="item.src" class="img" />
					<div class="cont1" v-if="index == 0">
						<div style="font-size: 36px">学蝉培训机构智慧管理系统</div>
						<div style="font-size: 18px; margin-top: 20px">
							专注提升机构管理效率，助力机构全面发展
						</div>
						<div class="freebtn" @click="dialogFormVisible = true">
							<div style="line-height: 38px;cursor: pointer;">免费试用</div>
						</div>
					</div>
					<div class="cont1" v-if="index == 1">
						<div style="font-size: 36px">学蝉体测</div>
						<div style="font-size: 18px; margin-top: 20px" @click="dialogFormVisible = true">
							智能体测数据分析，提升机构的专业水平
						</div>
						<div class="freebtn">
							<div style="line-height: 38px; cursor: pointer;" @click="dialogFormVisible = true">
								免费试用
							</div>
						</div>
					</div>
				</el-carousel-item>
			</el-carousel> -->
			<img :src="img[0].src" class="img" />
			<div class="cont1">
				<div style="font-size: 36px">学蝉培训机构智慧管理系统</div>
				<div style="font-size: 18px; margin-top: 20px">
					专注提升机构管理效率，助力机构全面发展
				</div>
				<div class="freebtn" @click="dialogFormVisible = true">
					<div style="line-height: 38px;cursor: pointer;">免费试用</div>
				</div>
			</div>
		</div>
		<ApplyDialog v-model="dialogFormVisible" />


		<!-- 产品功能 -->
		<div class="cont_1">
			<div class="content_1">
				<el-row :gutter="20">
					<el-col :span="6">
						<div class="content">
							<img src="../assets/img/ic_recruit.png" alt="" />
							<div class="cont_tit">招生不理想</div>
							<div class="txt">
								线下招生难度大，人力投入大成本高，招生推广方式单一化渠道少，转化效
								果难以衡量。
							</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="content">
							<img src="../assets/img/ic_educational.png" alt="" />
							<div class="cont_tit">教务管理不规范</div>
							<div class="txt">
								学员数据管理乱，排课表、课消表统计复杂，操作繁琐，容易出错，浪费资源成本高。
							</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="content">
							<img src="../assets/img/ic_news.png" alt="" />
							<div class="cont_tit">家长与机构粘性差</div>
							<div class="txt">
								重要消息通知不及时，家长无法及时了解孩子在校情况，家长参与度低，对教学不了解，满意度低。
							</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="content">
							<img src="../assets/img/ic_finance.png" alt="" />
							<div class="cont_tit">财务数据乱</div>
							<div class="txt">
								财务账目乱，收支流水、课消金额统计不齐全，无法掌握机构收支情况，决策分析无数据依据。
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<!-- 新闻动态 -->

		<div class="cont_2">
			<div class="title">提供解决方案</div>
			<div style="font-size: 18px;color: #515A67;margin-top: 20px; text-align: center;">
				全面赋能升级，提升机构运营效率
			</div>

			<div class="cont1">
				<el-row :gutter="20">
					<el-col :span="6">
						<div class="content" @click="tab = 0">
							<img src="../assets/img/pic_solve.png" alt="" />
							<div>提供解决方案备份</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="content" @click="tab = 1">
							<img src="../assets/img/pic_recruit.png" alt="" />
							<div>招生引流</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="content" @click="tab = 2">
							<img src="../assets/img/pic_finance.png" alt="" />
							<div>财务管理</div>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="content" @click="tab = 3">
							<img src="../assets/img/pic_familyschool.png" alt="" />
							<div>家校服务</div>
						</div>
					</el-col>
					<el-col :span="6" @click="tab = 4">
						<div class="content">
							<img src="../assets/img/pic_organization.png" alt="" />
							<div>机构管理</div>
						</div>
					</el-col>
				</el-row>

				<div class="tab1" v-if="tab === 0">
					<img src="../assets/img/pic.png" alt="" width="474" height="438" />
					<div class="content">
						<div class="tit1">
							机构信息化管理，提升办学效率
						</div>
						<div class="tit2">
							学员管理
						</div>
						<div class="txt">
							学员信息管理电子化，支持批量导入导出信息，标签分类管理，减少重复性工作，提高效率
						</div>
						<div class="tit2">
							班级管理
						</div>
						<div class="txt">
							建班、分班灵活操作，轻松查询班级课程，学员信息、班级出勤率，随时掌握班级情况
						</div>
						<div class="tit2">
							智能排课
						</div>
						<div class="txt">
							提供多种排课规则，自动生成排课表，缩短排课周期，节假日调课灵活设置，操作简单高效
							建班、分班灵活操作，轻松查询班级课程，学员信息、班级出勤率，随时掌握班级情况
						</div>
						<div class="tit2">
							智能考勤
						</div>
						<div class="txt">
							人脸识别考勤，自动点名，推送通知家长，在线请假审批，灵活安排补课，高效管理学员考勤
						</div>
					</div>
				</div>
				<div class="tab1" v-if="tab === 1">
					<img src="../assets/img/pic_admissions.png" alt="" width="474" height="438" />
					<div class="content">
						<div class="tit1">
							营销活动助招生，降低招生成本
						</div>
						<div class="tit2">
							意向学员管理
						</div>
						<div class="txt">
							系统记录管理意向学员，自定义学员标签，随时记录跟进情况，有效提高跟进效率
						</div>
						<div class="tit2">
							试听办理
						</div>
						<div class="txt">
							意向学员试听邀约安排，可视化试听列表，全面掌握学员试听情况，提升转化率。
						</div>
					</div>
				</div>
				<div class="tab1" v-if="tab === 2">
					<img src="../assets/img/pic-finance.png" alt="" width="474" height="438" />
					<div class="content">
						<div class="tit1">
							规范学校财务体系，确保资金运行安全
						</div>
						<div class="tit2">
							收支管理
						</div>
						<div class="txt">
							收支项目灵活设置，详细记录收支情况，确保账目一直，收支流水自动汇总，全面掌握收支情况。
						</div>
						<div class="tit2">
							课消统计
						</div>
						<div class="txt">
							课消情况实时统计，老师上课次数，学员到课率统计，掌握课消进度，根据到课率调整安排。
						</div>
					</div>
				</div>
				<div class="tab1" v-if="tab === 3">
					<img src="../assets/img/pic_homeschool.png" alt="" width="474" height="438" />
					<div class="content">
						<div class="tit1">
							增强学员黏性，提升家长满意度
						</div>
						<div class="tit2">
							实时查看
						</div>
						<div class="txt">
							教学管理透明化，系统自动推送各类消息提醒，家长及时了解孩子在校动态，不错过重要信息。
						</div>
						<div class="tit2">
							课堂点评
						</div>
						<div class="txt">
							记录学员课堂表现，图片/视频形式查看，家校活动评论，加强家长与机构间的互动。
						</div>
						<div class="tit2">
							成长档案
						</div>
						<div class="txt">
							记录学员成长点滴，让学员成长看得见，档案随时查看，提升服务品质，家长更信任。
						</div>
						<div class="tit2">
							在线作业
						</div>
						<div class="txt">
							支持文字、图片多种形式布置作业，推送作业通知，提醒未完成作业的学员，督促学员学习。
						</div>
					</div>
				</div>
				<div class="tab1" v-if="tab === 4">
					<img src="../assets/img/pic_mechanism.png" alt="" width="474" height="438" />
					<div class="content">
						<div class="tit1">
							多校区管理运营，掌握全局
						</div>
						<div class="tit2">
							多校管理
						</div>
						<div class="txt">
							可多个校区共同管理，总部可以实时查看各个校区经营数据情况，高效管理经营。
						</div>
						<div class="tit2">
							权限管理
						</div>
						<div class="txt">
							员工账号管理，各个校区，员工拥有独立账号，账号权限设置管理，校区信息安全有保障。
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 客户 -->
		<Contant/>
	</div>
</template>

<script>
	import {
		useStore
	} from "vuex";
	import {
		computed,
		ref
	} from "vue";
	import ApplyDialog from "@/components/ApplyDialog.vue";
	import Contant from "@/components/Contant.vue";
	export default {
		name: "Management",
		components: {
			ApplyDialog,
			Contant
		},
		setup() {
			const state = computed(() => useStore().state.client);
			const tab = ref(0);
			const dialogFormVisible = ref(false);
			const img = ref([{
					src: require("../assets/img/bg-banner.png")
				},
				// {
				// 	src: require("../assets/img/physical_banner.png")
				// },
			]);
			return {
				state,
				tab,
				dialogFormVisible,
				img
			};
		},
	};
</script>

<style lang="scss" scoped>
	.cont_1 {
		color: #281816;
		margin-top: 80px;
		position: relative;
		padding: 10px;
		text-align: center;

		.content_1 {
			width: 80%;
			margin: auto;

			.content {
				width: 280px;
				height: 280px;
				margin: auto;
			}

			.cont_tit {
				font-size: 22px;
				font-weight: bold;
			}

			.txt {
				font-size: 14px;
				line-height: 24px;
				width: 200px;
				height: 100px;
				margin: auto;
			}
		}
	}

	.cont_2 {
		margin-top: 60px;
		height: 850px;
		position: relative;

		.cont1 {
			font-size: 18px;
			color: #3a4259;
			width: 70%;
			margin-top: 100px;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);

			.el-col-6 {
				max-width: 20% !important;
			}

			.content {
				text-align: center;
			}
		}

		.tab1 {
			height: 600px;
			margin-top: 60px;
			display: flex;

			.content {
				margin-left: 40px;
				text-align: left;
				color: #281816;

				.tit1 {
					font-size: 36px;
				}

				.tit2 {
					font-size: 28px;
					margin-top: 18px;
				}

				.txt {
					font-size: 18px;
					margin-top: 18px;
				}
			}
		}
	}

	.title {
		padding-top: 40px;
		font-size: 36px;
		color: #281816;
		text-align: center;
	}

	.line {
		width: 140px;
		height: 2px;
		background-color: #ff7360;
		position: absolute;
		margin-top: 10px;
		left: 50%;
		transform: translateX(-50%);
	}

	.compatible {
		color: #474747;
		font-size: 0.14rem;
		margin-bottom: 0.8rem;
	}

	.footer {
		color: #0bd561;
		font-size: 0.4rem;
	}
</style>
